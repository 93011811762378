import { rejects } from 'assert';
import { resolve } from 'path';
import React, { useEffect, useState } from 'react';

const commonURL = "http://activeactive.magicpoker.com:8080/geodetectionrestapiservice";
function App() {
  const [regionBasedURL, setRegionBasedURL] = useState("");
  const [betMessage, setBetMessage] = useState("");



  const fetchData = async (url: string, params: any) => {
    return new Promise((resolve, rejects) => {
      fetch(url, {
        method: 'POST', // Specifies the HTTP method
        headers: {
          'Content-Type': 'application/json', // Ensures the server understands the data format
        },
        body: JSON.stringify(params), // Converts JavaScript object to JSON
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); // Parses the JSON response
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          console.error('Error:', error); // Handle errors
          rejects(error);
        });
    });

  };
  const getInitAction = async () => {
    const response: any = await fetchData(`${commonURL}/region/init`, { "action": "init" });
    console.log("initResponse:", response);
    setRegionBasedURL(response['origin']);
    getBetAction(response['origin']);
  };

  const getBetAction = async (url: string) => {
    console.log(">>>getBetAction>>>", url);;
    const response: any = await fetchData(`${url}/region/bet`, { "action": "bet", "bet": 20 });
    console.log("betResponse:", response);
    setBetMessage(response['message']);
  };

  return (
    <>
      <div>Main App page</div>
      <br />
      <input type='button' onClick={getInitAction} value="Initiate Actions"></input>
      <br /><br />
      <div>CommonURL: {commonURL}</div>
      <div>RegionBasedURL: {regionBasedURL}</div>
      <div>Bet message: {betMessage}</div>
    </>
  );
}

export default App;
